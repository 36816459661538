import React from 'react';
import Layout from 'components/Layout';
import { Link } from 'gatsby';
import '../styles/motion-deign-system.scss';
import DesignSystemSidebar from '../components/DesignSystemSidebar/DesignSystemSidebar';

const DesignSystems = () => (
  <Layout>
    <section>
      <div className="design-system-main">
        <DesignSystemSidebar />

        <div className="motion-design-system-middle">
          <div className="mds-content-wrapper">
            <h2 className="heading">Product</h2>

            <p>
              Welcome to the core of our product. Crafting a seamless, intuitive
              experience across every touchpoint. Our design philosophy ensures
              consistency and delight.
            </p>

            <Link to="/design-system/motion" className="single-product-wrapper">
              <div className="product-illustration">
                <img src="/assets/img/motion.svg" alt="" />
              </div>

              <div className="product-title">
                <span>Motion</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default DesignSystems;
