import { Link } from 'gatsby';
import React, { useState } from 'react';

const DesignSystemSidebar = () => {
  const [toggleMobileNav, settoggleMobileNav] = useState(false);

  const toggleMobileTrigger = () => {
    settoggleMobileNav((state) => !state);
  };
  return (
    <div className="design-system-sidebar">
      <div className="sidebar-content">
        <button type="button" onClick={toggleMobileTrigger}>
          <span>
            <svg width="10" height="6" fill="none" viewBox="0 0 10 6">
              <path
                stroke="#43366B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </span>
          Product
        </button>

        <div
          className={`sidebar-dropdown-wrapper ${
            toggleMobileNav ? 'active' : ''
          }`}>
          <div className="sidebar-dropdown">
            <div className="sidebar-dropdown-single">
              <Link to="/design-system/motion">Motion</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesignSystemSidebar;
